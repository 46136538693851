"use client";
import React from "react";
import logo from "../public/logo.webp";
import mlogo from "../public/logo.webp";
import uae from "../public/Flag_of_the_United_Arab_Emirates.webp";
import usa from "../public/usaFlag.webp";
import Image from "next/image";
import Link from "next/link";
import { AlignJustify, ArrowLeft, ArrowRight, Menu } from "lucide-react";
import { useRouter, usePathname } from "next/navigation";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useTranslations } from "next-intl";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

function Nav() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const router = useRouter();
  const local = pathname.includes("/en/")
    ? "en"
    : "ar"
    ? pathname.includes("en")
      ? "en"
      : "ar"
    : null;
  function switchLang() {
    if (pathname.includes("/ar/")) {
      router.push(pathname.replace("/ar/", "/en/"));
    } else if (pathname.includes("/en/")) {
      router.push(pathname.replace("/en/", "/ar/"));
    } else {
      if (local === "ar") {
        router.push(pathname.replace("ar", "en"));
      } else {
        router.push(pathname.replace("en", "ar"));
      }
    }
  }

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="flex justify-between fixed bg-white z-50 items-center font-montserrat w-screen py-3 px-10">
        <Link href={`/${local}`}>
          <Image
            src={logo}
            alt="logo"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Link>
        <ul className="lg:flex lg:gap-x-4 gap-2 hidden z-50">
          <li className={`text-slate-900 font-normal`}>
            <Link href={`/${local}`}>{t("home")}</Link>
          </li>
          <li className={`text-slate-900`}>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  className="p-0 h-[20px] text-base font-normal m-0 border-none bg-transparent text-inherit hover:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
                  variant="outline"
                >
                  {t("services")}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuGroup dir={local == "ar" ? "rtl" : "ltr"}>
                  <DropdownMenuItem>
                    <span>{t("service_1_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_2_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_3_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_4_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_5_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_6_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_7_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_devops_1_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_devops_2_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_devops_3_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_devops_4_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_devops_5_title")}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <span>{t("service_devops_6_title")}</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </li>
          <li className={`text-slate-900`}>
            <Button
              className="p-0 h-[20px] text-base font-normal m-0 border-none bg-transparent text-inherit hover:bg-transparent"
              onClick={() => scrollToSection("solutions")}
            >
              <span>{t("solution")} </span>
            </Button>
          </li>
          <li className={`text-slate-900`}>
            <Button
              className="p-0 h-[20px] text-base font-normal m-0 border-none bg-transparent text-inherit hover:bg-transparent"
              onClick={() => scrollToSection("marketplace")}
            >
              <span>{t("marketplace")} </span>
            </Button>
          </li>
          <li
            className={`${
              pathname.includes("pricing")
                ? "text-blue-900 font-bold"
                : "text-slate-900 text-base font-normal"
            }`}
          >
            <Link href={`/${local}/pricing`}>{t("pricing")}</Link>
          </li>
          <li
            className={`${
              pathname.includes("contact-us")
                ? "text-blue-900 font-bold"
                : "text-slate-900 text-base font-normal"
            }`}
          >
            <Link href={`/${local}/contact-us`}>{t("contact_us")}</Link>
          </li>
          <li
            className={`${
              pathname.includes("aboutus")
                ? "text-blue-900 font-bold"
                : "text-slate-900 text-base font-normal"
            }`}
          >
            <Link href={`/${local}/aboutus`}>{t("about_us")}</Link>
          </li>
          <div className="w-[2px] bg-gray-800"></div>
          <div className={`flex gap-x-2`}>
            <div onClick={switchLang} className="flex gap-x-2 cursor-pointer">
              <Image
                width={30}
                height={24}
                className="w-10 h-6 my-auto"
                alt="flag"
                src={local === "ar" ? usa : uae}
              />
              <h4>{local === "ar" ? "EN" : "AR"}</h4>
            </div>
            <div className={`block md:hidden mr-3 `}>
              <Sheet>
                <SheetTrigger>
                  <AlignJustify name="open Menu" />
                </SheetTrigger>
                <SheetContent>
                  <SheetHeader>
                    <SheetTitle>
                      <Image
                        src={mlogo}
                        alt="mint-ops"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                    </SheetTitle>

                    <SheetDescription>
                      <div>
                        <ul className="mt-8">
                          <li className="mb-8 text-right font-bold">
                            <Link href={`/${local}`}>{t("home")}</Link>
                          </li>
                          <li className="mb-8 text-right font-bold">
                            <Button
                              className="p-0 h-[20px] text-[16px]  m-0 border-none bg-transparent text-inherit hover:bg-transparent"
                              onClick={() => scrollToSection("services")}
                            >
                              <span>{t("services")} </span>
                            </Button>
                          </li>
                          <li className="mb-8 text-right font-bold">
                            <Button
                              className="p-0 h-[20px] text-[16px]  m-0 border-none bg-transparent text-inherit hover:bg-transparent"
                              onClick={() => scrollToSection("solutions")}
                            >
                              <span>{t("solution")} </span>
                            </Button>
                          </li>
                          <li className="mb-8 text-right font-bold">
                            <Button
                              className="p-0 h-[20px] text-[16px]  m-0 border-none bg-transparent text-inherit hover:bg-transparent"
                              onClick={() => scrollToSection("marketplace")}
                            >
                              <span>{t("marketplace")} </span>
                            </Button>
                          </li>
                          <li className="mb-8 text-right font-bold">
                            <Link href={`/${local}/pricing`}>
                              {t("pricing")}
                            </Link>
                          </li>
                          <li className="mb-8 text-right font-bold">
                            <Link href={`/${local}/contact-us`}>
                              {t("contact_us")}
                            </Link>
                          </li>
                          <li className="mb-8 text-right font-bold">
                            <Link href={`/${local}/aboutus`}>
                              {t("about_us")}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </SheetDescription>
                  </SheetHeader>
                </SheetContent>
              </Sheet>
            </div>
          </div>
        </ul>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              className={`p-4 h-[20px] text-[16px] border-none lg:hidden bg-transparent text-inherit hover:bg-transparent ${
                local == "ar" ? "mr-auto" : "ml-auto"
              }`}
              variant="outline"
            >
              <Menu />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuGroup>
              <DropdownMenuItem>
                <Link href={`/${local}`}>{t("home")}</Link>
              </DropdownMenuItem>
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  <span>{t("services")}</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem>
                      <Link href={`/${local}`}>{t("services")}</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Link href={`/${local}`}>{t("services")}</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Link href={`/${local}`}>{t("services")}</Link>
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
              <DropdownMenuItem>
                <Link href={`/${local}`}>{t("solution")}</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href={`/${local}`}>{t("marketplace")}</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href={`/${local}/pricing`}>{t("pricing")}</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href={`/${local}/contact-us`}>{t("contact_us")}</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href={`/${local}/aboutus`}>{t("about_us")}</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <div
                  onClick={switchLang}
                  dir={local == "ar" ? "rtl" : "rtl"}
                  className="flex gap-x-2 cursor-pointer"
                >
                  <Image
                    width={30}
                    height={24}
                    className="w-5 h-3 my-auto"
                    alt="flag"
                    src={local === "ar" ? usa : uae}
                  />
                  <h4>{local === "ar" ? "EN" : "AR"}</h4>
                </div>{" "}
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <Button
          className={`capitalize hover:from-blue-800 hover:to-blue-500 rounded-full bg-gradient-to-b from-blue-800 to-blue-500 flex justify-center items-center lg:m-0`}
        >
          <Link href={`/${local}/contact-us`}>{t("git")}</Link>
          {local == "ar" ? <ArrowLeft size={16} /> : <ArrowRight size={16} />}
        </Button>
      </div>
    </>
  );
}

export default Nav;
